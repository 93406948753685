import React from 'react';
import Footer from "../../core/layout/footer/footer";
import EnquireNow from "../dataCuration/component/enquireNow";
import careersMobileImage from "../../assets/img/careersMobileImage.png";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images';

const ClinicalView = (props) => {
    const {isModelOpen, openModel, closeModal, isMobileView}=props;

    const renderHeaderUI = () => {
        return (
            <div className="pt-0 cover-background top-space-margin sm-pb-0 mb-3"
                 style={{
                     backgroundImage:`url(${isMobileView ? careersMobileImage : s3BaseURL+'/services/servicesBanner.jpg'})`
                 }}>
                <div className="page-container">
                    <div className="row align-items-end h-200px sm-h-100px ">
                        <div className="col-lg-9 col-md-6 position-relative text-left page-title-extra-large d-flex justify-content-left align-item-end">
                            <div className="justify-content-left header-img-text slideUpQuickly"> Clinical Data</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderClinicalView = () => {
        return (
            <div>

                <div className='services-section-title'>Clinical Data Services</div>

                <div className='news-event-subHeading mb-4 pb-2'>
                    Clinical Data Services refer to a range of services related to the analysis, annotation and
                    interpretation of data collected during clinical trials or healthcare research studies. These
                    services are essential for organizing the enormous clinical data in a more structured and
                    standardized manner. CDS help in transforming clinical trial data into meaningful insights for
                    decision-making, reporting, and regulatory submission.
                </div>
                <div className='services-section-subHeading'>Here are the key components of clinical data services:</div>
                <div>
                    <div className='text-header'>
                        <div className=''>
                            <div className='services-section-subTitle'>1. Clinical Data Management</div>
                        </div>
                        <div className='mb-3'>
                            <div className='news-event-subHeading mb-2'>
                                <span className='fw-600 text-black'>· Data Integration: </span>Organizing data from different
                                sources (e.g., clinicaltrials.gov, EU Clinical Trials Register) into a unified dataset.
                            </div>
                            <div className='news-event-subHeading'>
                                <span className='fw-600 text-black'>· Database Design:</span> Structuring databases for data
                                storage and retrieval.
                            </div>

                        </div>
                        <div>
                            <div className='services-section-subTitle'>2. Clinical Data Technologies </div>
                        </div>
                        <div className='news-event-subHeading mb-2'>
                            <span className='fw-600 text-black'>· Electronic Data Capture: </span> Using software tools to
                            capture, manage, and store clinical data electronically, reducing errors and increasing
                            efficiency.
                        </div>

                        <div className='news-event-subHeading'>
                            <span className='fw-600 text-black'>· Clinical Data Systems: </span>Integrating various
                            technologies and systems for seamless data flow
                            and management.
                        </div>

                        <div className='news-event-subHeading mt-4' style={{lineHeight: '20px'}}>
                            In summary, clinical data services play a critical role in research and provide actionable
                            insights for regulatory approval or further clinical development.
                        </div>
                    </div>
                </div>
                <div className='services-section-title'>CASE STUDY - 01</div>
                <div>
                    <div className='news-event-subHeading mb-3'>
                        Clinical Trial Eligibility Annotation: The eligibility criteria are typically outlined in the
                        trial protocol and serve as a set of standards to ensure that participants are appropriate for
                        the study AND fullfill the objectives, minimizing risks and ensuring valid, reliable results.
                    </div>
                    <div className='news-event-subHeading'>
                        Annotations on clinical trial eligibility can provide a deeper understanding of each criterion.
                        The annotation associates each and every terminology with the relevant ontologies for a better
                        structurization of the data.
                    </div>
                </div>
                <div className='services-section-title'>CASE STUDY - 02</div>
                <div>
                    <div className='news-event-subHeading mb-3'>
                        Annotation of a Consent Form in a Clinical Trial refers to the process of providing
                        detailed, clear explanations or notes on the content of an informed consent document.
                        Annotations are especially important when there are complex legal or medical terms that may need
                        further clarification. Our Annotation services provide the key word annotation, classification
                        and association with the relevant ontologies.
                    </div>
                    <div className='news-event-subHeading'>
                        Annotation of the consent form is a vital process in clinical
                        trials, ensuring that the participants have a full understanding of what they are consenting to.
                        It enhances transparency, trust, and informed decision-making, and contributes to the ethical
                        conduct of clinical research.
                    </div>
                </div>
            </div>
        )
    };


    const renderUI = () => {
        return (
            <div>
                {renderHeaderUI()}
                <div className="pb-0">
                    <div className="page-container mb-5">
                        {renderClinicalView()}
                    </div>
                </div>

            </div>
        )
    };

    return (
        <div>

            {renderUI()}
            <Footer {...props}/>
            <div className="floating-tab cursor" onClick={()=>{openModel()}}>
                <div className="popup-with-move-anim" >
                    <div className="tab-text">Enquire Now</div>
                </div>
            </div>
            {isModelOpen &&
            <EnquireNow
                closeModal={closeModal}
            />
            }
        </div>
    );
};

export default ClinicalView;