import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";
import EnquireNow from "../dataCuration/component/enquireNow";
import careersMobileImage from "../../assets/img/careersMobileImage.png";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images';

const ResearchInformaticsView = (props) => {
    const {isModelOpen, openModel, closeModal, isMobileView} = props;
    const renderDataCuration = () => {
        return (
            <div>
                <div className='services-section-title'>
                    Scientific Data Engineering Services
                </div>
                <div>
                    <div className='news-event-subHeading'>Evolvus offers data engineering services designed to
                        help research teams manage, integrate, and analyse complex scientific data at scale. We analyse
                        data quality, volume, and complexity to develop a tailored data engineering strategy to ensure
                        data is accurately processed, stored, and transformed into actionable insights.
                    </div>
                </div>

                <div className='services-section-title pb-0'>Key Components of Evolvus Scientific Data Engineering
                    Services
                </div>
                <div className='services-section-subTitle'>1. Data Integration and Transformation</div>
                <div className='ps-2 ms-2'>
                    <div className='news-event-subHeading mb-2 pb-1'>Evolvus specializes in seamlessly integrating data
                        from
                        diverse sources, such as experimental data, internal databases as well as external proprietary
                        and public datasets, and transform it into a usable format.
                    </div>
                    <div className='news-event-subHeading'>
                        <div className='mb-2'>
                            <span
                                className='fw-600 services-sub-text text-black'>• ETL (Extract, Transform, Load): </span> Our
                            ETL processes
                            aggregate data from multiple research sources and repositories, transforming raw data into
                            standardized formats that can be easily analyzed.
                        </div>
                        <div>
                            <span className='fw-600 services-sub-text text-black'>• Data Pipelines: </span> Using
                            technologies like Apache
                            Kafka, we streamline the movement of data
                            across systems for smooth data transfer.
                        </div>
                    </div>
                </div>
                <div className='services-section-subTitle'>2. Data Validation</div>
                <div className='ps-2 ms-2'>
                    <div className='news-event-subHeading mb-2 pb-1'>Evolvus provides comprehensive data validation
                        services
                        to ensure the highest quality of data for research purposes.
                    </div>
                    <div className='news-event-subHeading'>
                        <span className='fw-600 services-sub-text text-black'>• Data Validation: </span>Our tools and
                        methodologies
                        automatically validate data accuracy, ensuring that all data conforms to predefined standards,
                        protocols, and formats.
                    </div>
                </div>
                <div className='services-section-subTitle'>3. Data Management Solutions</div>
                <div className='ps-2 ms-2'>
                    <div className='news-event-subHeading mb-2 pb-1'>Evolvus offers scalable and secure data management
                        solutions for storing vast amounts of scientific data. We design robust, high-performance
                        systems that enable easy access to data.
                    </div>
                    <div className='news-event-subHeading mb-2'>
                        <span className='fw-600 services-sub-text text-black'>• Data Warehousing: </span>We build
                        centralized data
                        warehouses using technologies such as <span className='fw-600 primary-text '>Amazon Redshift, Snowflake, Google,
                        BigQuery</span> to consolidate large datasets, making them readily available for analysis.
                    </div>
                    <div className='news-event-subHeading mb-2'>
                        <span className='fw-600 services-sub-text text-black'>• Cloud Storage:</span> Our cloud storage
                        solutions
                        leverage <span className='fw-600 primary-text'>Amazon S3, Google Cloud Storage, and Azure Blob Storage</span> for
                        secure and scalable data
                        storage.
                    </div>
                    <div className='news-event-subHeading'>
                        <span className='fw-600 services-sub-text text-black'>• Data Lakes: </span>We implement data
                        lakes to store large
                        volumes of raw data in its native format, making it easier to process and analyse using big data
                        tools.
                    </div>
                </div>
                <div className='services-section-subTitle '>4. Data Analytics</div>
                <div className='ps-2 ms-2'>
                    <div className='news-event-subHeading mb-2 pb-1'>Evolvus provides data visualization services to
                        turn
                        complex datasets into easy-to-understand, interactive charts and reports.
                    </div>
                    <div className='news-event-subHeading mb-2'>
                        <span className='fw-600 services-sub-text text-black'>• Visualization Tools:</span> We leverage
                        advanced tools
                        like <span className='fw-600 primary-text '>Tableau, Power BI,</span> and <span
                        className='fw-600 primary-text '>D3.js </span>to create interactive dashboards that present
                        research findings in a clear and actionable format.
                    </div>
                    <div className='news-event-subHeading'>
                        <span className='fw-600 services-sub-text text-black'>• Reporting:</span> Our team also
                        specializes in building
                        custom reporting systems that provide insights in real time, allowing researchers to track
                        progress and results easily.
                    </div>
                </div>
                <div className='services-section-subTitle'>5. Data Security</div>
                <div className='ps-2 ms-2'>
                    <div className='news-event-subHeading mb-2 pb-1'>Evolvus follows the highest standards of data
                        security
                        and compliance in all our data engineering services.
                    </div>
                    <div className='news-event-subHeading'>
                        <span className='fw-600 services-sub-text text-black'>• Data Encryption:</span> We implement
                        encryption
                        techniques to protect research data both at rest and in transit, ensuring that sensitive
                        information remains secure.
                    </div>
                </div>
                <div className='services-section-title'>Why Choose Evolvus for Scientific Data
                    Engineering?
                </div>
                <div className='news-event-subHeading mb-2'>
                    <span className='fw-600 services-sub-text text-black'>• Domain Expertise: </span>Our team has deep
                    expertise in
                    scientific domains and data engineering best practices.
                </div>
                <div className='news-event-subHeading mb-2'>
                    <span className='fw-600 services-sub-text text-black'>• Tailored Solutions:</span> Based upon our
                    understanding of
                    the complexities of scientific research, we provide customized solutions that address specific needs
                    and challenges.
                </div>
                <div className='news-event-subHeading mb-2'>
                    <span className='fw-600 services-sub-text text-black'>• Cutting-edge Technology: </span> We leverage
                    the latest
                    technologies, frameworks, and methodologies to deliver high-performance data engineering solutions.
                </div>
                <div className='news-event-subHeading mb-2'>
                    <span className='fw-600 services-sub-text text-black'>• Scalable Solutions:</span> Our solutions can
                    scale to handle
                    large and complex datasets.
                </div>
                <div className='news-event-subHeading mb-2'>
                    <span className='fw-600 services-sub-text text-black'>• Data Security and Privacy: </span>We
                    prioritize data security
                    and privacy to protect sensitive information.
                </div>
                <div className='news-event-subHeading'>
                    <span className='fw-600 services-sub-text text-black'>• Collaboration and Support:</span> To ensure
                    that our
                    solutions meet your evolving needs, we work closely with your research teams, providing ongoing
                    support. We employ agile methodologies to deliver high-quality solutions in a timely manner.
                </div>

                <div className='services-section-title'>Key Technologies
                </div>
                <div className='services-section-subHeading'>Data management technologies</div>
                <div className='services-section-subTitle mt-4'>1. Databases</div>
                <div className='ps-2 ms-2'>
                    <div className='news-event-subHeading mb-2'>
                        <span className='fw-600 primary-text text-black'>• Relational Databases (SQL)</span> such as
                        MySQL,
                        PostgreSQL, MS SQL Server and Oracle.
                    </div>
                    <div className='news-event-subHeading mb-2'>
                        <span className='fw-600 services-sub-text text-black'>• NoSQL Databases</span> such as MongoDB,
                        Cassandra, and
                        CouchDB,
                    </div>
                    <div className='news-event-subHeading'>
                        <span className='fw-600 services-sub-text text-black'>• Graph Databases </span>such as Neo4j.
                    </div>
                </div>
                <div className='services-section-subTitle'>2. Data Warehousing</div>
                <div className='ps-2 ms-2'>
                    <div className='news-event-subHeading mb-2'>
                        <span className='fw-600 primary-text'>• Cloud Data Warehousing </span>such as Amazon Redshift,
                        Google BigQuery, Snowflake
                    </div>
                    <div className='news-event-subHeading'>
                        <span className='fw-600 services-sub-text text-black'>• Data Lakes </span>such as Apache Hadoop,
                        Apache Spark.
                    </div>
                </div>
                <div className='services-section-subTitle '>3. Data Integration Platforms</div>
                <div className='ps-2 ms-2'>
                    <div className='news-event-subHeading'>
                        <span className='fw-600 primary-text'>• ETL (Extract, Transform, Load)</span> pipelines such as
                        Spark SQL, Apache Kafka, Amazon Glue
                    </div>
                </div>

                <div className='services-section-subHeading mt-4 pt-3 mb-4'>Cloud Computing and Storage</div>
                <div>
                    <div className='news-event-subHeading mb-2'>
                        <span className='fw-600 services-sub-text text-black mb-2'>• Cloud Platforms</span> such
                        as <span
                        className='fw-600 services-sub-text text-black'>Amazon Web Services (AWS), Google Cloud Platform (GCP), </span>and <span
                        className='fw-600 services-sub-text text-black'>Microsoft Azure </span>
                    </div>
                    <div className='news-event-subHeading'>
                        <span className='fw-600 services-sub-text text-black'>• Cloud Data Storage </span>such as <span
                        className='fw-600 services-sub-text text-black'>AWS S3, Google Cloud Storage, </span>and<span
                        className='fw-600 services-sub-text text-black'> Azure Blob Storage.</span><br/>
                    </div>
                </div>
                <div className='services-section-subHeading mt-4 pt-2 mb-4'>Data Analysis</div>
                <div className='mb-1'>
                    <div className='news-event-subHeading mb-2'>
                        <span className='fw-600 services-sub-text'>• </span>Interactive reports and dashboards such
                        as<span
                        className='fw-600 services-sub-text text-black'> Tableau, Power BI</span>
                    </div>
                    <div className='news-event-subHeading'>
                        <span className='fw-600 services-sub-text'>•</span> Static, interactive, and animated data
                        visualizations such as<span
                        className='fw-600 services-sub-text text-black'> Matplotlib, Plotly, D3.js</span><br/>
                    </div>
                </div>

                <div className='services-section-subHeading mt-4 pt-2 mb-4'>ELN & LIMS Services</div>
                <div className='news-event-subHeading mb-3'>In the rapidly advancing fields of scientific research,
                    managing laboratory data and workflows effectively is paramount to maintaining productivity,
                    ensuring compliance, and achieving scientific breakthroughs. Electronic Laboratory Notebooks (ELNs)
                    and Laboratory Information Management Systems (LIMS) have emerged as indispensable tools to
                    streamline workflows, enhance data integrity, and improve overall productivity.
                </div>
                <div className='news-event-subHeading'>
                    However, implementing and optimizing these systems can be a complex task. Evolvus offers specialized
                    <span className='fw-600 primary-text'> ELN (Electronic Lab Notebook) </span>and <span
                    className='fw-600 primary-text'> LIMS (Laboratory Information Management System) </span>consulting
                    services to help organizations implement and optimize these essential tools for modern lab
                    management. Our experienced team provide tailored solutions to address your specific needs, ensuring
                    a smooth transition and optimal performance.
                </div>
                <div className='mb-1'>

                    <div className='services-section-title'>Our Key Services:</div>
                    <div className='news-event-subHeading'>
                        <div className='services-section-subTitle'>Needs Assessment and System Selection:</div>
                        • Understand your team’s requirements, evaluate available ELN and LIMS solutions, and select the
                        appropriate solution.
                        <div className='services-section-subTitle'> Workflow Optimization:</div>
                        <div className='mb-2'>• Identify current processes for delays or inefficiencies</div>
                        <div className='mb-2'>• simplify processes by removing redundant steps</div>
                        <div className='mb-2'>• Automate routine tasks such as data entry or report generation.</div>
                        <div className='services-section-subTitle'> Implementation and Configuration:</div>
                        <div className='mb-2'>• Implement the system to cover end-to-end processes and workflows</div>
                        <div className='mb-2'>• Customize it to align with data standards</div>
                        <div className='mb-2'>• Configure user access to ensure security and compliance.</div>
                        <div className='services-section-subTitle'>Data Migration and Integration:</div>
                        <div className='mb-2'>• Develop data migration strategies and integrate with other laboratory
                            instruments and
                            systems
                        </div>
                        <div className='mb-2'>• Optimize data flow between systems for better accessibility.</div>
                        <div className='services-section-subTitle'>Optimization and Continuous Improvement:</div>
                        <div className='mb-2'>• Identify opportunities for process improvement and efficiency gains
                        </div>
                        <div>• Monitor system performance and user satisfaction.</div>
                    </div>
                </div>
                <div className='services-section-title'>Why Choose Evolvus for ELN & LIMS Services?</div>
                <div className='mb-1'>
                    <div className='news-event-subHeading'>
                        <span className='fw-600 text-black'>• Expertise: </span>
                        Evolvus has extensive experience in the implementation and optimization of ELN and LIMS systems.
                        Our team brings deep domain knowledge and technical expertise required to provide optimum
                        solutions.<br/><br/>
                        <span className='fw-600 text-black'>• Customized Solutions: </span>
                        We work closely with your teams to deliver tailored ELN and LIMS services that fit your specific
                        workflows and process requirements.<br/><br/>
                        <span className='fw-600 text-black'>• End-to-End Support: </span>
                        From initial consultation and system selection to full-scale implementation, training, and
                        ongoing support, Evolvus provides a comprehensive suite of services to ensure the success of
                        your laboratory management systems.<br/>
                    </div>
                </div>
                <div className='mb-1'>
                    <div className='news-event-subHeading'>
                        Whether you are seeking to streamline your lab operations or enhance collaboration, Evolvus is
                        your trusted partner in implementing effective, scalable laboratory management solutions.
                    </div>
                </div>

                <div className='services-section-title'>Scientific Software Development Services</div>
                <div className='news-event-subHeading'>
                    Driving Scientific Innovation Through Software Development
                    <br/><br/>
                    Evolvus is a leading provider of scientific software development services, empowering researchers to
                    accelerate their discoveries. We combine deep domain expertise with extensive software development
                    experience, and our approach ensures that the software we develop is not only scientifically sound
                    but also user-friendly, scalable, and tailored to the specific needs of our clients.
                    <br/><br/>
                    We collaborate closely with research teams at every stage of the project, from conceptualization to
                    deployment, to ensure that the final solution meets the highest standards of performance, usability,
                    and scalability.
                </div>
                <div>
                    <div className='services-section-title'>Our Expertise in Scientific Software Development
                    </div>
                    <div className='news-event-subHeading'>
                        <div className='services-section-subTitle'>Custom Software Solutions:</div>
                        • We create software applications to meet your unique research requirements. This includes web
                        and desktop applications, cloud-based solutions, as well as development of libraries, APIs, and
                        plugins.
                        <div className='services-section-subTitle'>Data Analysis and Visualization Tools:</div>
                        • We develop applications to extract meaningful insights from complex data.
                        <div className='services-section-subTitle'>Scientific Workflow Automation:</div>
                        • We streamline research workflows by automating repetitive tasks.
                        <div className='services-section-subTitle'>Cloud-Based Solutions:</div>
                        • We deploy scalable and flexible software solutions on cloud platforms.
                    </div>
                </div>
                <div className='services-section-title '>Why Choose Evolvus for Scientific Software Development?
                </div>
                <div className='mb-1'>
                    <div className='news-event-subHeading'>
                        <div className='services-section-subTitle'>Domain Expertise:</div>
                        Our team has deep expertise in scientific domains and software development best
                        practices.
                        <div className='services-section-subTitle'>Customized Solutions:</div>
                        We understand the complexities of scientific research and provide customized solutions that
                        address your specific needs and challenges.
                        <div className='services-section-subTitle'>Cutting-edge Technology:</div>
                        We leverage the latest technologies, frameworks, and methodologies to deliver high-performance,
                        scalable data engineering solutions.
                        <div className='services-section-subTitle'>Data Security and Privacy:</div>
                        We prioritize data security and privacy to protect sensitive information.
                        <div className='services-section-subTitle'>Collaboration and Support:</div>
                        We work closely with your teams, providing ongoing support and ensuring that our solutions meet
                        evolving needs. We employ agile methodologies to deliver high-quality solutions in a timely
                        manner.
                    </div>
                </div>
                <div className='services-section-title'>Key Technologies</div>
                <div className='news-event-subHeading'>
                    <div className='services-section-subTitle'>Software frameworks and languages:</div>
                    <div className='mb-2'>• Frontend frameworks such as React, Angular</div>
                    <div className='mb-2'>• Middleware frameworks such as Node.js, Spring Boot</div>
                    <div className='mb-2'>• Programming languages such as Java, Python, JavaScript, R, C#, MATLAB</div>
                    <div className='services-section-subTitle'>Databases:</div>
                    <div className='mb-2'><span className='fw-600 text-black'>• Relational Databases (SQL)</span> such
                        as MySQL, PostgreSQL,
                        MS SQL Server, and Oracle
                    </div>
                    <div className='mb-2'><span className='fw-600 text-black'>• NoSQL Databases </span>such as MongoDB,
                        Cassandra, and CouchDB
                    </div>
                    <div className='mb-2'><span className='fw-600 text-black'>• Graph Databases</span> such as Neo4j
                    </div>
                    <div className='services-section-subTitle'>Cloud Computing and Storage:</div>
                    <div className='mb-2'><span className='fw-600 text-black'>• Cloud Platforms</span> such as Amazon
                        Web Services (AWS),
                        Google Cloud Platform (GCP), and Microsoft Azure
                    </div>
                    <div className='mb-2'><span className='fw-600 text-black'>• Cloud Data Storage</span> such as AWS
                        S3, Google Cloud
                        Storage, and Azure Blob Storage
                    </div>
                    <div className='services-section-subTitle'>Scientific Tools & Libraries:</div>
                    <div className='mb-2'>• RDKit, CDKit</div>
                    <div className='mb-2'>• OpenBabel</div>
                    <div className='mb-2'>• OSRA</div>
                    <div className='mb-2'>• Pubmed: APIs and databases</div>
                    <div className='mb-2'>• PDF Box</div>
                    <div className='services-section-subTitle'>Data Visualization and Reporting Tools:</div>
                    <div className='mb-2'>• Interactive reports and dashboards such as <span
                        className='fw-600 text-black'>Tableau, Power BI</span></div>
                    <div className='mb-2'>• Static, interactive, and animated data visualizations such as <span
                        className='fw-600 text-black'> Matplotlib, Plotly, D3.js</span></div>
                    <div className='services-section-title'>Key Methodologies:</div>
                    <div className='mb-2'>• Agile development</div>
                    <div className='mb-2'>• FAIR principles</div>
                </div>
                <div className='news-event-subHeading mt-3'>
                    <div className='mb-3'>Evolvus empowers your business with innovative IT
                        solutions.
                        Our expert team leverages advanced technologies to optimize your IT infrastructure, streamline
                        operations, and enhance decision-making. Evolvus' informatics teams are dedicated to
                        transforming
                        data into actionable insights, ensuring easy, reliable, and cost-effective access to scientific
                        knowledge.
                    </div>
                    <div>
                        By applying highly skilled resources and industry-leading software and technologies, Evolvus
                        delivers reliable IT services and products. Our customized technology solutions are designed to
                        meet
                        your specific requirements, enabling you to maximize the value of your IT investments and
                        improve
                        your research outcomes.
                    </div>

                </div>

            </div>
        )
    };
    const renderHeaderUI = () => {
        return (
            <div className="pt-0 cover-background top-space-margin sm-pb-0"
                 style={{
                     backgroundImage: `url(${isMobileView ? careersMobileImage : s3BaseURL + '/services/servicesBanner.jpg'})`
                 }}>
                <div className="page-container">
                    <div className=" align-items-end h-200px sm-h-100px ">
                        <div
                            className="position-relative text-left page-title-extra-large d-flex justify-content-left align-item-end">
                            <div className="justify-content-left header-img-text slideUpQuickly"> Research
                                Informatics
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    const renderUI = () => {
        return (
            <div>
                {renderHeaderUI()}
                <div className="pb-5">
                    <div className="page-container">
                        {renderDataCuration()}
                    </div>
                </div>

            </div>
        )
    };

    return (
        <div>

            {renderUI()}
            <Footer {...props}/>
            <div className="floating-tab cursor" onClick={() => {
                openModel()
            }}>
                <div className="popup-with-move-anim">
                    <div className="tab-text">Enquire Now</div>
                </div>
            </div>
            {isModelOpen &&
            <EnquireNow
                closeModal={closeModal}
            />
            }
        </div>
    );
};

export default ResearchInformaticsView;
