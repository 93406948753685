import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import Footer from "../../../core/layout/footer/footer";
import EnquireNow from "../../../services/dataCuration/component/enquireNow";
import DataHeaderView from "../dataHeaderView";
import TabUiView from "../tabUiView";
import HeaderImage from "../headerImage";

let s3BaseURLHome = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images/home';

const LiceptorDatabaseView = (props) => {
    const {isModelOpen, closeModal, openModel, isXSView} = props;
    const parms = useLocation()

    useEffect(() => {
        // setTimeout(() => {
        //     document.querySelector('.fade-in').classList.add('fade-in-delay');
        // }, 1000);
    }, []);

    const renderLiceptorDatabase = () => {
        return (
            <div className="fade show" id="">
                <div
                    className={(isXSView ? 'mb-4 pb-3 text-start' : '') + " col-md-7 col-lg-4 col-12 pe-md-0 py-md-0 pb-md-0 px-sm-4"}>
                    <img
                        src={`${s3BaseURLHome}/Liceptor.png`}
                        alt="Liceptor"
                        className="rounded mb-md-4 pb-md-1 mb-sm-4 pb-sm-3 mb-sm-1 data-section-image-container"
                    />
                </div>
                <div className={" col-md-12 col-12 text-start px-sm-4"}>
                    <div
                        className={(isXSView ? 'mb-4 ' : '') + "p-0 mb-2 mb-lg-3 pb-lg-1 mb-md-4 mb-sm-4 services-section-title mt-4"}>Liceptor
                        Database
                    </div>
                    <div className='mb-4 news-event-subHeading '>
                        Liceptor is the largest small molecule ligand database covering over 9 million 2D
                        structures, associated bioactivity data including assays, functions, and therapeutic
                        indications. Derived exclusively from English and Asian patents, the database focuses
                        on targets like GPCRs, ion channels, central nervous system (CNS) transporters, kinases,
                        proteases, phosphatases, nuclear receptors, and cytochrome P450.
                    </div>
                    <button onClick={openModel}
                            className="btn btn-extra-large btn-rounded with-rounded popup-with-move-anim btn-base-color btn-box-shadow text-transform-none position-relative">Explore
                        Now
                        <span className="bg-white text-white rounded-circle px-2 py-2">
                                    <i className="fa-solid fa-arrow-right text-dark-gray"></i></span>
                    </button>
                </div>
            </div>
        )
    };

    const renderDataListUI = () => {
        return (
            <div className="bg-white section-divider">
                <div className="page-container">
                    <div className="">
                        {renderLiceptorDatabase()}
                    </div>
                </div>
            </div>
        )
    };

    const renderUI = () => {
        return (
            <div>
                <HeaderImage
                    {...props}
                    currentDatabase={'Liceptor'}
                />
                <div className="page-container">
                    <div className='pt-4'>
                        <TabUiView {...props}/>
                    </div>
                </div>
                {renderDataListUI()}
                {isModelOpen &&
                <EnquireNow
                    closeModal={closeModal}
                />
                }
            </div>
        )

    };

    return (
        <div>
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
};

export default LiceptorDatabaseView;