import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";
import EnquireNow from "../dataCuration/component/enquireNow";
import careersMobileImage from "../../assets/img/careersMobileImage.png";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images';

const BioInformaticsView = (props) => {
    const {isModelOpen, openModel, closeModal, isMobileView}=props;

    const renderHeaderUI = () => {
        return (
            <div className="pt-0 cover-background top-space-margin sm-pb-0 mb-3"
                 style={{
                     backgroundImage:`url(${isMobileView ? careersMobileImage : s3BaseURL+'/services/servicesBanner.jpg'})`
                 }}>
                <div className="page-container">
                    <div className="row align-items-end h-200px sm-h-100px ">
                        <div className="col-lg-9 col-md-6 position-relative text-left page-title-extra-large d-flex justify-content-left align-item-end">
                            <div className="justify-content-left header-img-text slideUpQuickly"> Bioinformatics</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderBioInformatics = () => {
        return (
            <div className='page-container'>
                <div className='services-section-title'>Bioinformatics</div>
                <div >
                    <div className='news-event-subHeading '>We offer bioinformatics services to leading
                        pharmaceutical and biotechnology industries for their NCE and NBE related research.
                        Bioinformatics, which is the melding of molecular biology with computer science, is essential
                        for the use of genomic information in understanding human diseases and in the identification of
                        new molecular targets for drug discovery.
                    </div>
                </div>

                <div className='services-section-title'>Sequence Analysis</div>
                <div >
                    <div className='news-event-subHeading '>Evolvus helps to establish functional, structural, or
                        evolutionary relationships between the sequences of nucleotides and amino acids in order to
                        determine sequence of DNA and proteins. Protein sequence analysis is of the foremost importance
                        for structure prediction of a target protein. Suitable techniques within pairwise sequence
                        alignment or multiple sequence alignment are used for performing sequence analysis.
                    </div>
                </div>

                <div className='services-section-title'>Microarray Data Analysis</div>
                <div >
                    <div className='news-event-subHeading'>
                        We have good experience in using DNA microarray technology in expression profiling based on
                        genomics data generated from preclinical research as well as from clinical trials. The analysis
                        performed by experienced biostatisticians specialising in microarray data analysis actively
                        interrogates the data and provides robust and valid measures of gene regulation, and principal
                        data trends.
                    </div>
                </div>

                <div className='services-section-title'>Genome Annotation</div>
                <div >
                    <div className='news-event-subHeading'>
                        Genome annotation is the process of attaching biological information to sequences and to
                        identify structural and functional sequences within the genome. Automatic annotation tools
                        perform all these aspects by computer analysis, as opposed to manual annotation which involves
                        human expertise. Evolvus helps scientists to save time and improve quality by providing advanced
                        softwares for data analysis. We assign the functional and morphological classification as per
                        the client's need by creating genome knowledge repositories and gene regulation studies.
                    </div>
                </div>

                <div className='services-section-title'> Homology Modelling</div>
                <div >
                    <div className='news-event-subHeading'>
                        Evolvus undertakes 3D structure generation of difficult to crystallise protein targets like
                        GPCRs, ion channels and transporters by homology modelling. With the help of advanced
                        computational tools, group of skilled protein modellers generate optimised and well-validated 3D
                        structure of the target protein.
                    </div>
                </div>

                <div className='services-section-title'> Structure based Drug Design</div>
                <div >
                    <div className='news-event-subHeading'>
                        Using 3D structure of a target protein obtained either through X-ray crystallography or homology
                        modelling, we undertake SBDD of NCEs by using various computational methods like de novo design,
                        virtual screening, active-site mapping and structure-based pharmacophore development.
                    </div>
                </div>

                <div className='services-section-title'>Proteomics in Drug Discovery</div>
                <div >
                    <div className='news-event-subHeading pb-1'>
                        Proteomics plays a critical role in drug discovery by providing a deeper understanding of
                        biological systems, disease mechanisms, and drug targets. Here are key use cases of
                        proteomics in drug discovery:
                    </div>
                    <div className='news-event-subHeading'>
                        <div className='services-section-subTitle'>1. Identification of Drug Targets </div>
                        Protein Expression and Post Translational Modification Profiling: Helps in
                        identify proteins that are altered in disease, helping to find potential drug targets like
                        enzymes, receptors, and signaling molecules involved in the disease.
                    </div>
                    <div className='news-event-subHeading'>
                        <div className='services-section-subTitle'>2. Biomarker Discovery and Therapeutic Efficacy </div>
                        Diagnostic and Prognostic Biomarkers: Identification of proteins that can serve as
                        biomarkers for disease diagnosis, prognosis, or treatment response of a drug
                        candidate.
                    </div>
                    <div className='news-event-subHeading '>
                        <div className='services-section-subTitle'>3. Mechanism of Action (MoA)</div>
                        Understanding Drug Mechanisms: Exploring the insight into how a drug works at
                        the molecular level by identifying the proteins it interacts with and understanding its
                        impact on cellular processes.
                    </div>
                    <div className='news-event-subHeading'>
                        <div className='services-section-subTitle'>4. Hit Identification and Validation</div>
                        Target Validation: Validation of the interaction between a drug and its intended
                        target, confirming that a particular protein is truly involved in the drug
                        pharmacokinetics and pharmacodynamics studies.
                    </div>
                    <div className='news-event-subHeading'>
                        <div className='services-section-subTitle'>5. Toxicology and Safety Profiling</div>
                        Off-Target Effects: Helping in detection of off-target interactions that could lead to
                        adverse drug reactions. By profiling protein interactions and modifications,
                        researchers can identify toxic effects that may not be immediately apparent in
                        preclinical studies.
                    </div>
                    <div className='news-event-subHeading'>
                        <div className='services-section-subTitle'>6. Drug Repurposing</div>
                        Understanding Existing Drugs: Identification of new targets and pathways impacted
                        by existing drugs, helping researchers find new uses for approved treatments.
                    </div>
                    <div className='news-event-subHeading'>
                        <div className='services-section-subTitle'>7. Personalized Medicine</div>
                        Patient-Specific Profiling: Analysis of an individual’s protein profile to identify the
                        most suitable drugs.
                    </div>
                </div>
            </div>
        )
    };

    const renderUI = () => {
        return (
            <div>
                {renderHeaderUI()}
                <div className="pb-0">
                    <div className="mb-5">
                        {renderBioInformatics()}
                    </div>
                </div>

            </div>
        )
    };

    return (
        <div>

            {renderUI()}
            <Footer {...props}/>
            <div className="floating-tab cursor" onClick={()=>{openModel()}}>
                <div className="popup-with-move-anim">
                    <div className="tab-text">Enquire Now</div>
                </div>
            </div>
            {isModelOpen &&
            <EnquireNow
                closeModal={closeModal}
            />
            }
        </div>
    );
};

export default BioInformaticsView;