import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import Footer from "../../../core/layout/footer/footer";
import EnquireNow from "../../../services/dataCuration/component/enquireNow";
import DataHeaderView from "../dataHeaderView";
import HeaderImage from "../headerImage";
import TabUiView from "../tabUiView";

let s3BaseURLHome = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images/home';

const ProximerDatabaseView = (props) => {
    const {isModelOpen, closeModal, openModel, isXSView} = props;
    const parms = useLocation()

    useEffect(() => {
        // setTimeout(() => {
        //     document.querySelector('.fade-in').classList.add('fade-in-delay');
        // }, 1000);
    }, []);


    const renderProximersDatabase = () => {
        return (
            <div className="fade show pt-2" id="">
                <div
                    className={(isXSView ? 'mb-4 pb-3 text-start' : '') + " col-md-7 col-lg-4 col-12 pe-md-0 py-md-0 pb-md-0 px-sm-4"}>
                    <img src={s3BaseURLHome + '/Proximers.png'}
                         alt='Proximers'
                         className="rounded mb-md-4 pb-md-1 mb-sm-4 pb-sm-3 mb-sm-1 data-section-image-container"
                    />
                </div>
                <div className={ " col-md-12 col-12 text-start px-sm-4"}>
                    <div
                        className={(isXSView ? 'mb-4 ' : '') + "services-section-title mt-4 text-dark p-0 mb-2 mb-lg-3 mb-md-4 mb-sm-4"}>
                        Proximers Database
                    </div>
                    <div className='mb-4 section-text text-start text-gray '>
                        <div className='news-event-subHeading'>Its the worlds most comprehensive database covering different classes or
                        types of Protein
                        Degraders, such as Heterobifunctional Degraders, IMiDs, Molecular Glues, AUTACs, PHOTACs,
                        CLIPTACs, HETEROTACs, HOMOTACs, TRAFTACs, LYTACs.
                        </div>
                        <div className='services-section-subTitle'>
                            Features And Licensing Of Proximers Degraders Database
                        </div>
                        <ul className='m-0 px-3'>
                            <li className='mb-2 news-event-subHeading'>Degraders and the associated bioassay values, all of which is manually curated,
                                generating
                                a tremendous chemical and biological repository.
                            </li>
                            <li className='mb-2 news-event-subHeading'>Data curated from patents from US, WIPO, EP and CN, JP, KR patent offices and also
                                from
                                other sources.
                            </li>
                            <li className='mb-2 news-event-subHeading'>Monthly updated and delivered</li>
                            <li className='mb-2 news-event-subHeading'>No restriction on users</li>
                            <li className='mb-2 news-event-subHeading'>Data dump format is RDF, JSON or SQL</li>
                            <li className='mb-2 news-event-subHeading'>Flexible Pricing</li>
                        </ul>
                    </div>
                    <button onClick={openModel}
                            className="btn btn-extra-large btn-rounded with-rounded popup-with-move-anim btn-base-color btn-box-shadow text-transform-none position-relative">Explore
                        Now
                        <span className="bg-white text-white rounded-circle px-2 py-2">
                                    <i className="fa-solid fa-arrow-right text-dark-gray"></i></span>
                    </button>
                </div>
            </div>
        )
    };

    const renderDataListUI = () => {
        return (
            <div className="bg-white section-divider">
                <div className="page-container">
                    <div className="">
                        {renderProximersDatabase()}
                    </div>
                </div>
            </div>
        )
    };

    const renderUI = () => {
        return (
            <div>
                <HeaderImage
                    {...props}
                    currentDatabase={'Proximers'}
                />
                <div className="page-container">
                    <div className='pt-4'>
                        <TabUiView {...props}/>
                    </div>
                </div>
                {renderDataListUI()}
                {isModelOpen &&
                <EnquireNow
                    closeModal={closeModal}
                />
                }
            </div>
        )

    };

    return (
        <div>
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
};

export default ProximerDatabaseView;
