import React, {useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './home.css'
import Footer from "../core/layout/footer/footer";
import OurProductsView from "./ourProductsView";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL + '/Images/home';

const HomeView = (props) => {
    const {isSMView, isXSView} = props;
    const [index, setIndex] = useState(0);

    const {isMobileView, isLGView} = props;

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const renderCarouselUI = () => {
        let bannerHeight = isMobileView ? isXSView ? 'calc(100vh - 300px)' : 'calc(100vh - 200px)' : 'calc(100vh - 40px)';
        return (
            <Carousel
                activeIndex={index}
                nextIcon={isSMView || isXSView ? <></> :
                    <div className='nextIcon'><i className="fa-solid fa-chevron-right"/></div>}
                prevIcon={isSMView || isXSView ? <></> :
                    <div className='nextIcon'><i className="fa-solid fa-chevron-left"/></div>}
                onSelect={handleSelect}
                slide={true}
                fade={false}
                style={{marginTop: -60}}>
                <Carousel.Item interval={5000}>
                    <div className={isMobileView ? "bannerImgOneMobile" : 'bannerImgOne'}
                         style={{height: bannerHeight}}>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                    <div className={isMobileView ? 'bannerImgTwoMobile' : 'bannerImgTwo'}
                         style={{height: bannerHeight}}>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                    <div className={isMobileView ? 'bannerImgThreeMobile' : 'bannerImgThree'}
                         style={{height: bannerHeight}}>
                    </div>
                </Carousel.Item>
            </Carousel>
        )
    };

    const renderNoiseUI = () => {

        return (
            <div className="page-container section-divider">
                <div className={isMobileView ? "" : "row"}>
                    <div className={isMobileView ? "section-divider" : "col-md-5"}>
                        <div className='home-title '>
                            Data without context = <span className="about-count text-black">NOISE</span>
                        </div>
                        <div className="section-text mt-4 text-gray" style={{paddingLeft: 1.5}}>
                            Evolvus is engaged in the business of providing products and solutions based on curation of
                            various life sciences datasets and scouting for new data impacting Life Sciences, Medicine,
                            Chemistry, and Genomics, and related products and services that enable enterprises to take
                            an informed research-driven business decision.
                            We are one of the largest global producers of various databases within the space of Compound
                            Bioactivity, Metabolism/ADME, Protein Degraders, Conjugates, RNA, and Biomarkers. Our team
                            has expertise in a wide range of disciplines - from chemistry and medicine to molecular
                            biology to data engineering best practices. We provide customized and cost-effective
                            scalable solutions, with a goal of helping enterprises “Structure the Unstructured Data”.
                        </div>
                    </div>
                    <div className={(isMobileView) ? "" : "col-md-7 text-center"}>
                        <div className={isMobileView ? "mb-5" : 'block-left-divider'}>
                            <div
                                className={(isMobileView || isLGView) ? "title-text mb-3 text-black text-start" : "title-text mb-3 text-black d-flex"}>
                                <div className='d-flex align-items-center'>
                                    <div className='home-green-dot'/>
                                    Empowering Drug Discovery –
                                </div>
                                <div className={isMobileView ? 'ps-3 ms-2' : 'ps-1'}
                                     style={{fontWeight: 'var(--font-style-normal)'}}>One DATUM at a Time
                                </div>
                            </div>
                            <img src={s3BaseURL + '/datum.jpg'}
                                 alt={"Magnifier Graphic"}
                                 className={"magnifier-img image-tag"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderUI = () => {
        return (
            <div>
                <div className='mb-3'>
                    {renderCarouselUI()}
                </div>
                <div className='pt-4'>
                    {renderNoiseUI()}
                </div>
                <OurProductsView {...props}/>
            </div>
        )
    };

    return (
        <div>
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
};

export default HomeView;

