import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";
import DataCurationComponent from "../../core/components/dataCurationComponent/dataCurationComponent";
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL+'/Images';
import EnquireNow from "./component/enquireNow";
import careersMobileImage from "../../assets/img/careersMobileImage.png";

const DataCurationView = (props) => {
    const {isModelOpen, openModel, closeModal, isMobileView}=props;

    const renderHeaderUI = () => {
        return (
            <div className="pt-0 cover-background top-space-margin sm-pb-0 mb-3"
                 style={{
                     backgroundImage:`url(${isMobileView ? careersMobileImage : s3BaseURL+'/services/servicesBanner.jpg'})`
                 }}>
                <div className="page-container">
                    <div className="row align-items-end h-200px sm-h-100px ">
                        <div className="col-lg-9 col-md-6 position-relative text-left page-title-extra-large d-flex justify-content-left align-item-end">
                            <div className="justify-content-left header-img-text slideUpQuickly"> Data Curation</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderDataCuration = () => {
        return (
            <div className='page-container'>
                <div className="row ">
                    <div className=''>
                        <div className='services-section-title primary-text mb-4'>Indexing of Markush Structures</div>
                        <div className='mb-1'>
                            <div className='news-event-subHeading'>Markush structures work as a breakthrough for patent searches. They seem to be very useful in answering patent queries.
                                The indexing and retrieval of Markush structures is one of the most challenging aspect of patent information. Our services provide improvements in indexing along with less restrictive access conditions.
                                These systems are often linked to processes for new drug discovery and provide a common interface to a diverse range of specific structure databases.<br/>
                            </div>
                            <div className='news-event-subHeading'>
                                <div className='services-section-subTitle'>
                                    We offer:
                                </div>
                                <div className='mb-2'>
                                    • Markush structures from patents covered to date
                                </div>
                                <div className='mb-2'>
                                    • Citations for the Markush structure-containing patents
                                </div>
                                <div className='mb-2'>
                                    • Information includes bibliographic data, abstract, indexing
                                </div>
                                <div >
                                    • Structure updating on a daily basis
                                </div>
                            </div>
                        </div>

                        <div className='services-section-title'>Knowledge Management</div>
                        <div className='mb-1'>
                            <div className='news-event-subHeading mb-3'>We offer comprehensive knowledge management services, and perform indexing and annotation of chemical and biological data from patents and literature on an industrial scale for a variety of scientific enterprises, including eminent publishers and renowned pharmaceutical R&Ds.
                            </div>
                            <div className='news-event-subHeading'>
                                Our dedicated facilities are networked through secure software and comprise a team of professional pharmacologists, biochemists, molecular biologists, and organic chemists, specifically trained in the process of data annotation. Our unique delivery model combines elements of both onshore and offshore resourcing to ensure the highest levels of quality at a significantly lower cost than local alternatives.
                                We provide 'Prototype project' wherein the pilots are constructed as per client's requirement.
                            </div>
                        </div>

                        <div className='services-section-title'>Customization & Delivery</div>
                        <div>
                            <div className='news-event-subHeading'>
                                <div className='mb-2'>
                                    Datasets are delivered as database, set of files or via API.
                                </div>
                                <div>
                                    Datasets may require customizations based upon our customers’ preferences before delivery.
                                </div>
                                <div className='services-section-subTitle'>
                                    Data customizations
                                </div>
                                <div  className='mb-2'>
                                    • Schema
                                </div>
                                <div  className='mb-2'>
                                    • Range value formats
                                </div>
                                <div  className='mb-2'>
                                    • Field naming conventions
                                </div>
                                <div  className='mb-2'>
                                    • Entity relationships
                                </div>
                                <div  className='mb-2'>
                                    • Data
                                </div>
                                <div  className='mb-2'>
                                    • Value conversion from one standard Unit to another
                                </div>
                                <div  className='mb-2'>
                                    • Special characters
                                </div>
                                <div>
                                    • Text formats
                                </div>
                            </div>

                            <div className='news-event-subHeading'>
                                <div className='services-section-subTitle'>
                                    Delivery pipeline customizations
                                </div>
                                <div className='mb-2'>• Output format as per a Customer’s preference –</div>
                                <div className='mb-2'>• JSON, XML, RDF, SQL, SQL+SDF, API</div>
                                <div className='mb-2'>
                                    • Dataset Grouping – set of files (size/number of file limits), set naming conventions
                                </div>
                                <div>
                                    • End points – Cloud repositories such as AWS S3 buckets, FTP servers etc.
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderUI = () => {
        return (
            <div >
                {renderHeaderUI()}
                <div className='d-flex justify-content-center section-divider-small page-container' >
                    <img src={isMobileView ? s3BaseURL+'/services/dataCurationPipelineMobile.png' : s3BaseURL+'/services/dataCurationPipeline.png'}
                         style={{
                             width:isMobileView ? '90%' : '100%'
                         }}/>
                </div>
                <div>
                    <div className="mb-5">
                        {renderDataCuration()}
                    </div>
                </div>

            </div>
        )
    };

    return (
        <div>

            {renderUI()}
            <Footer {...props}/>
            <div className="floating-tab cursor" onClick={()=>{openModel()}}>
                <div className="popup-with-move-anim" >
                    <div className="tab-text">Enquire Now</div>
                </div>
            </div>
            {isModelOpen &&
            <EnquireNow
                closeModal={closeModal}
            />
            }

        </div>
    );
};

export default DataCurationView;

